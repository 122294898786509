<template>
  <div>
    <van-search
        @click="toSearchView"
        :readonly = true
        shape="round"
        background="#eb4450"
        placeholder="搜索"
    />
    <div style="overflow: hidden; display:flex;">
        <div style="flex: 2;">
            <van-sidebar v-model="activeKey">
                <van-sidebar-item 
                :title="item.cateName"  
                v-for="(item,index) in KindList" 
                :key="item.cart_name" 
                @click="changeSelectKind(item.cateId,index)"/>
            </van-sidebar>
        </div>
        <div style="flex: 7;">
            <div v-for="(item,index) in contentList" :key="index">
                <div class="contentTitle">
                    <a href="" class="delimiter">/</a>
                    <a href="" style="font-weight :bold;color:black;padding-left:5px;padding-right:5px">{{item.cateName}}</a>
                    <a href="" class="delimiter">/</a>
                </div>
                <van-grid square :border="false">
                    <van-grid-item v-for="(item1,index1) in item.children" :key="index1">
                        <div class="kindOne" @click="toGoodList(item1.cateId)">
                            <div>
                                <img :src="item1.imageSrc" style="width: 77%"/>
                            </div>
                            <div style="width: 100%;">
                                <a href="" style="font-size:12px;color:black;">{{item1.cateName}}</a>
                            </div>
                        </div>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
    <div  style="width: 100%; height:50px;"></div>
  </div>
</template>

<script>
export default {
  data(){
    return {
        activeKey: 0,
        KindList:[],
        contentList:[]
    };
  },
  created() {
      this.activeKey = this.$store.getters.getKindActiveKey;
      this.getKindList();
  },
  methods: {
    toSearchView(){
        if(this.$route.path != "/SearchView"){
            this.$router.push("/SearchView");
        }
    },
    getKindList(){
      this.$axios.get("/list/category").then((res) => {
        if(res.status == 200){
          this.KindList = res.data;
          var cate_Id = res.data[this.activeKey].cateId;
          this.getKindContentList(cate_Id);
        }
      });
    },
    getKindContentList(cate_Id){
        this.$axios.get("/list/catesContentById/"+ cate_Id).then((res) => {
            if(res.status == 200){
                this.contentList = res.data;
            }
        });
    },
    changeSelectKind(value,index){
        this.$store.commit('setKindActiveKey', index);
        this.getKindContentList(value);
        this.activeKey = index;
    },
    toGoodList(value){
        if(this.$route.path != "/GoodListView"){
            this.$router.push({path:'GoodListView', query:{kindChildrenId: value}});
        }
    },
  }
}
</script>


<style scoped>
.van-sidebar-item--select::before {
    height: 60px;
}
.contentTitle{
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;;
}
.delimiter{
  color: #ccc;
  padding: 0 10rpx;
}
.kindOne{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
}
.van-grid-item__content {
    margin-top: 5px;
}
</style>