<template>
    <div>
        <van-nav-bar
            title="支付"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <div v-if="addressList.length == 0">
            <van-button type="primary" size="large" @click="toAddressView">请选择地址</van-button>
        </div>
        <div v-else>
        <van-address-list
            v-model="chosenAddressId"
            :list="addressList"
            @edit="toChangeAddress"
        />
        </div>
        <div v-for="(item,index) in orderList" :key="index">
        <van-card
            :num="item.buyNum"
            :price="item.goodsPrice"
            :title="item.goodsName"
            :thumb="item.imageSrc1"
        />
        </div>
        <div style="width:100%; height:50px;"></div>
        <van-submit-bar 
        style="z-index: 1000;" 
        :price="orderPrice" 
        :disabled = "(addressList.length == 0 ||  orderPrice == 0)? true : false"
        button-text="提交订单" 
        @submit="onSubmit" />
    </div>
</template>

<script>
export default {
  data() {
    return {
        orderPrice: 0,
        orderList:[],
        chosenAddressId: 1,
        addressList:[],
    }
  },
  created() {
      var tempMyOrderAddress = this.$store.getters.getOrderAddress;
      if(Object.keys(tempMyOrderAddress).length == 0){
            //选择默认地址
            this.getUserAddressDefault();
      }else{
            //选择store保持的地址
            this.$set(this.addressList, 0, tempMyOrderAddress)
            this.chosenAddressId = tempMyOrderAddress.id;
      }
      this.getBuyCart();
  },
  methods: {
      onClickLeft(){
          this.$router.back();
      },
      onSubmit(){
        var userId = this.$store.getters.getUserId;
        let goodId = "";
        let goodNum = "";
        let goodPrice = "";
        var buyCart = this.$store.getters.getBuyCart || [];
        for(var i=0;i<buyCart.length;i++){
            if(goodId == ""){
                goodId = goodId + buyCart[i].id;
            }else{
                goodId = goodId + "," + buyCart[i].id;
            }
            if(goodNum == ""){
                goodNum = goodNum + buyCart[i].buyNum;
            }else{
                goodNum = goodNum + "," + buyCart[i].buyNum;
            }
            if(goodPrice == ""){
                goodPrice = goodPrice + buyCart[i].goodsPrice + "";
            }else{
                goodPrice = goodPrice + "," + buyCart[i].goodsPrice + "";
            }
        }
        var params = {
            userId: userId,
            goodId: goodId,
            goodNum: goodNum,
            seller: this.addressList[0].name,
            address: this.addressList[0].address,
            sellerIphone: this.addressList[0].tel,
            price: this.orderPrice*1.0/100.0,
            goodPrice: goodPrice
        };
        this.$axios.post("/pay/addToOrderList",params).then((res) => {
            if(res.status == 200){
                var cart = this.$store.getters.getUserCart || [];
                var tempCart = [];
                if(this.$route.query.cartId != undefined){
                    for(var i=0;i<cart.length;i++){
                        if(!cart[i].checked){
                            tempCart.push(cart[i]);
                        }
                    }
                    this.$store.commit("setUserCart",tempCart);
                }
                this.toMyOrderView();
            }
        });
      },
      toMyOrderView(){
          if(this.$route.path != "/MyOrderView"){
            this.$router.push('MyOrderView');
          }
      }
      ,
      toChangeAddress(){
          if(this.$route.path != "/AddressView"){
            this.$router.push({path:'AddressView', query:{addressId: this.chosenAddressId}});
          }
      },
      getUserAddressDefault(){
          var userId = this.$store.getters.getUserId;
          this.$axios.get("/useraddress/default/"+userId).then((res) => {
            if(res.status == 200){
                this.addressList = [];
                if(res.data.length != 0){
                    let temp = {
                        id : res.data[0].id,
                        name : res.data[0].name,
                        tel: res.data[0].phone,
                        address: res.data[0].province + res.data[0].city + res.data[0].area + " " + res.data[0].completeAddress,
                        isDefault: res.data[0].isDefault == 1 ? true:false
                    }
                    this.addressList.push(temp);
                    this.chosenAddressId = res.data[0].id;
                }
            }
        });
      },
      toAddressView(){
        if(this.$route.path != "/AddressView"){
            this.$router.push({path:'AddressView', query:{addressId: -1}});
        }
      },
      getBuyCart(){
          var buyCart = this.$store.getters.getBuyCart || [];
          this.orderList = buyCart;
          this.orderPrice = 0;
          for(var i=0;i<buyCart.length;i++){
              this.orderPrice = this.orderPrice + buyCart[i].goodsPrice * 100 * buyCart[i].buyNum;
          }
      }
  }
};
</script>

<style scoped>
.van-address-list{
    padding: 12px 12px 0px;
}
</style>