<template>
  <div>
      <van-search
        @click="toSearchView"
        :readonly = true
        shape="round"
        background="#eb4450"
        placeholder="搜索"
      />
      <div class="swipeStyle">
        <van-swipe lazy-render :autoplay="3000">
          <van-swipe-item  v-for="item in swipeList" :key="item.goodId" @click="toGoodDetailView(item.goodId)">
            <img :src="item.imageSrc" style="width: 90%;" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="cateStyle">
        <div v-for="item in catesList" :key="item.catesId" @click="toGoodListView(item.catesId)">
          <img :src="item.imageSrc" style="width: 80%"/>
        </div>
      </div>
      <div class="floorList">
        <div v-for="(item,itemindex) in floorList" :key="itemindex">
            <div class="floorTitle">
              <img :src="item.ctitleFloor.titleSrc" style="width: 90%"/>
            </div>
            <div v-for="(item1,index) in item.cproductFloor" :key="index">
              <div v-if="index == 0" class='floorOne'>
                <img class="floorImg" :src="item1.imageSrc" @click="toGoodDetailView(item1.goodId)"/>
              </div>
              <div v-else-if="index == 1" class='floorOne'>
                <img 
                style=" border-bottom: 1vw solid #fff;"
                class="floorImgStyle1" 
                :src="item1.imageSrc"
                @click="toGoodDetailView(item1.goodId)"
                />
              </div>
              <div v-else-if="index == 2" class='floorOne'>
                <img 
                style=" border-bottom: 1vw solid #fff;"
                class="floorImgStyle1" 
                :src="item1.imageSrc"
                @click="toGoodDetailView(item1.goodId)"
                />
              </div>
              <div v-else-if="index == 3" class='floorOne'>
                <img class="floorImgStyle1" 
                :src="item1.imageSrc"
                @click="toGoodDetailView(item1.goodId)"
                />
              </div>
              <div v-else-if="index == 4" class='floorOne'>
                <img 
                class="floorImgStyle1" 
                :src="item1.imageSrc"
                @click="toGoodDetailView(item1.goodId)"
                />
              </div>
            </div>
        </div>
      </div>
      <div style="width: 100%; height:50px;"></div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      swipeList:[],
      catesList:[],
      floorList:[]
    };
  },
  created() {
    this.getHomeViewSwiperList();
    this.getHomeViewCatesList();
    this.getHomeViewFloorList();
  },
  methods: {
    toSearchView(){
        if(this.$route.path != "/SearchView"){
            this.$router.push("/SearchView");
        }
    },
    getHomeViewSwiperList(){
      this.$axios.get("/list/swiper").then((res) => {
        if(res.status == 200){
          this.swipeList = res.data
        }
      });
    },
    getHomeViewCatesList(){
      this.$axios.get("/list/cates").then((res) => {
        if(res.status == 200){
          this.catesList = res.data
        }
      });
    },
    getHomeViewFloorList(){
      this.$axios.get("/list/floor").then((res) => {
        if(res.status == 200){
          this.floorList = res.data
        }
      });
    },
    toGoodDetailView(value){
        if(this.$route.path != "/GoodDetailView"){
            this.$router.push({path:'GoodDetailView', query:{goodId: value}});
        }
    },
    toGoodListView(value){
        if(this.$route.path != "/GoodListView"){
            this.$router.push({path:'GoodListView', query:{kindId: value}});
        }
    }
  }
}
</script>


<style scoped>
.swipeStyle {
  width: 100%;
  text-align: center;
}
.cateStyle{
  padding-top: 10px;
  display: flex;
  width: 100%;
  text-align: center;
}
.floorTitle{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  width: 23%;
}
.floorList{
  overflow: hidden;
}
.floorOne{
  text-align: center;
  float: left;
  width: 33.33%;
}
.floorImgStyle1{
  width: 90%;
  height: 16.665vw;
}
.floorImg{
  width: 90%;
  height: 34.33vw;
}
</style>