<template>
  <div id="ChangePassword">
    <van-nav-bar
      title="编辑密码"
      left-text=""
      right-text="保存"
      left-arrow
      @click-left="onClickLeft()"
      @click-right="updateInfo()"
    >
    </van-nav-bar>
    <div class="ChangePassword">
      <van-field
        v-model="oldPassword"
        type="password"
        name="密码"
        label="原始密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写原始密码' }]"
      />
      <van-field
        v-model="newPassword"
        type="password"
        name="新密码"
        label="新密码"
        placeholder="新密码"
        :rules="[{ required: true, message: '请填写新密码' }]"
      />
      <van-field
        v-model="reNewPassword"
        type="password"
        name="重复新密码"
        label="重复新密码"
        placeholder="密码"
        :rules="[
          { required: true, message: '请填写密码' },
          { validator: checkPassword, message: '两次密码输入不一致' },
        ]"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      newPassword: "",
      reNewPassword: "",
      oldPassword: "",
    };
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    checkPassword() {
      if (this.reNewPassword == "") {
        return false;
      } else if (this.newPassword != this.reNewPassword) {
        return false;
      } else {
        return true;
      }
    },
    updateInfo() {
      var userId = this.$store.getters.getUserId;
      let params = {
        id: userId,
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
      };
      this.$axios.post("/user/changePassword", params).then((res) => {
        if (res.status == 200) {
          if (res.data == 1) {
            Toast.success("修改成功");
            this.$store.commit("setToken", "");
            this.$router.push("/Login");
          } else {
            this.newPassword = "";
            this.reNewPassword = "";
            this.oldPassword = "";
            Toast.fail("修改失败");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.ChangePassword {
  width: 92%;
  margin: 0 auto;
  box-shadow: 2px 2px 10px #ddd;
  margin-top: 25px;
}
</style>