<template>
<div class="main">
  <div class="head">
    <!-- <a class="titleText">甜多家庭农场</a> -->
    <router-link class="titleText" to="/Login">甜多家庭农场</router-link>
  </div>
  <div class="foot">
  <a class="footText" href="https://beian.miit.gov.cn/">浙ICP备2021018673号-2</a>
  </div>
</div>
</template>

<script>
export default {
  data(){
    return {
    };
  },
  created() {
  },
  methods: {
  }
}
</script>


<style scoped>
html{
  width: 100%;
  height: 100%;
}
body{
  width: 100%;
  height: 100%;
}
.main{
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.head{

}
.titleText{
  font-size: 45px;
  font-weight: bold;
}
.foot{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.footText{
  font-size: 16px;
  color: #000;
}
</style>