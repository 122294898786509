<template>
  <div id="Setting">
    <van-nav-bar
      title="设置"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form>
      <van-field
        is-link
        readonly
        name="password"
        label="修改密码"
        input-align="right"
        @click="toChangePassword()"
      />
      <van-goods-action>
        <van-goods-action-button
          type="danger"
          text="退出登录"
          @click="loginOut()"
        />
      </van-goods-action>
    </van-form>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    toChangePassword() {
      if (this.$route.path != "/ChangePasswordView") {
        this.$router.push("/ChangePasswordView");
      }
    },
    loginOut() {
      this.$store.commit("setToken", "");
      this.$router.push("/Login");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>