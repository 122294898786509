<template>
    <div>
        <van-nav-bar
            title="增加收货地址"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-address-edit
            :area-list="areaList"
            :address-info="AddressInfo"
            show-postal
            show-set-default
            show-search-result
            :search-result="searchResult"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @change-detail="onChangeDetail"
        />
    </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  data(){
    return {
      areaList,
      searchResult: [],
      timer: null,
      AddressInfo:{
      }
    };
  },
  created() {
  },
  methods: {
      onClickLeft(){
          this.$router.back();
      },
      onSave(value) {
          var userId = this.$store.getters.getUserId;
          let temp = {
              userId:userId,
              name:value.name,
              phone:value.tel,
              province:value.province,
              city: value.city,
              area:value.county,
              completeAddress:value.addressDetail,
              postal:value.postalCode, 
              isDefault:value.isDefault ? 1 : 0,
              addressCode:value.areaCode    
          };
          this.$axios.post("/useraddress/add",temp).then((res) => {
              if(res.status == 200){
                  this.$router.back();
              }
          });
      },
      onDelete() {
      },
      onChangeDetail() {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
          },500);
      }
  }
}
</script>

<style scoped>

</style>