<template>
    <div>
        <van-nav-bar
            title="我的收货地址"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-address-list
            v-model="chosenAddressId"
            :list="addressList"
            default-tag-text="默认"
            @add="onAddAddress"
            @edit="onEditAddress"
            @select="onSelectAddress"

        />
    </div>
</template>

<script>
export default {
  data(){
    return {
        chosenAddressId: -1,
        addressList: []
    };
  },
  created() {
    this.getAddressList();
    this.chosenAddressId = parseInt(this.$route.query.addressId);
  },
  methods: {
      onClickLeft(){
          this.$router.back();
      },
      onAddAddress() {
        if(this.$route.path != "/AddAddressView"){
            this.$router.push("/AddAddressView");
        }
      },
      onEditAddress(e) {
        if(this.$route.path != "/EditAddressView"){
            this.$router.push({path:'EditAddressView', query:{addressId: e.id}});
        }
      },
      onSelectAddress(e){
        if(this.$route.query.addressId != undefined){
          this.$store.commit('setOrderAddress', e);
          this.onClickLeft(); 
        }
      },
      getAddressList(){
        var userId = this.$store.getters.getUserId;
        this.$axios.get("/useraddress/list/"+userId).then((res) => {
            if(res.status == 200){
                this.addressList = [];
                for(var i=0;i<res.data.length;i++){
                    let temp = {
                        id : res.data[i].id,
                        name : res.data[i].name,
                        tel: res.data[i].phone,
                        address: res.data[i].province + res.data[i].city + res.data[i].area + " " + res.data[i].completeAddress,
                        isDefault: res.data[i].isDefault == 1 ? true:false
                    }
                    this.addressList.push(temp);
                }
            }
        });
      }
  }
}
</script>

<style scoped>

</style>