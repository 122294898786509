<template>
  <div
    id="MyInfo"
  >
    <van-nav-bar
      class="navBar"
      title=""
      left-text=""
      right-text=""
      :border="false"
      style="background-color:#eb4450"
    >
      <!-- <van-icon
        name="more-o"
        size="22"
        slot="left"
        @click="showleftFunction()"
      /> -->
    </van-nav-bar>
    <div class="infoArea">
      <img
        v-if="this.userInfoForm.photo !== 'undefined'"
        class="touxiangphoto float-left"
        :src="userInfoForm.avatarUrl"
        alt=""
      />
      <div class="touxiangtxt">
        <div>
          <span style="font-size: 17px; font-weight: bold">{{
            this.userInfoForm.nickName
          }}</span>
        </div>
        <div>
          <span class="userId">{{ "用户id:" + this.userInfoForm.id }}</span>
        </div>
      </div>
    </div>
    <div class="summary">
      <div v-if="this.userInfoForm.userIntroduce != ''">
        <span>{{ this.userInfoForm.userIntroduce }}</span>
      </div>
      <div v-else>
        <span>这个人很懒，什么都没写</span>
      </div>
    </div>
    <div class="sex">
      <div v-if="this.userInfoForm.gender === 1">
        <span>{{ "♂" + this.userInfoForm.age + "岁" }}</span>
      </div>
      <div v-else>
        <span>{{ "♀" + this.userInfoForm.age + "岁" }}</span>
      </div>
    </div>
    <div class="setting">
      <div
        class="float-left"
        style="font-size: 10px; margin-right: 10px; text-align: center"
      >
        <div><span></span></div>
        <span></span>
      </div>
      <div
        class="float-left"
        style="font-size: 12px; margin-right: 10px; text-align: center"
      >
        <div><span></span></div>
        <span></span>
      </div>
      <div
        class="float-left"
        style="font-size: 12px; margin-right: 10px; text-align: center"
      >
        <div><span></span></div>
        <span></span>
      </div>
      <div class="float-right setInfo" @click="toSetView()">
        <van-icon class="setIcon" name="setting-o" size="15px" slot="right" />
      </div>
      <div class="float-right editInfo" @click="toChangeMyInfoView()">
        <span>编辑资料</span>
      </div>
    </div>
    <div class="MyInfoArea" :style="'height:' + fullHeight + 'px;'">
    <div style="width:96%;padding-left:2%;">
        <van-grid>
            <van-grid-item style="background:#ccc" icon="balance-list-o" text="我的订单" @click="toMyOrderView"/>
            <van-grid-item icon="star-o" text="我的收藏" @click="toMyCollectView"/>
            <van-grid-item icon="comment-o" text="我的评论" @click="toMyCommentView"/>
            <van-grid-item icon="browsing-history-o" text="我的足迹" @click="toMyFootprintView"/>
        </van-grid>
        <van-form>
            <van-field
                style="margin-top:11px;"
                is-link
                readonly
                name="myAddress"
                label="收货地址管理"
                input-align="right"
                @click="toMyAddressView"
            />
            <van-field
                style="margin-top:11px;"
                readonly
                name="customerService"
                input-align="right"
            >
              <template #left-icon>
                <div class="customerServiceStyle">
                    <span>联系客服</span>
                </div>
              </template>
              <template #right-icon>
                <div class="customerServiceStyle">
                    <span>400-619-400</span>
                </div>
              </template>
            </van-field>
            <van-field
                readonly
                name="toUsYourIdea"
                label="意见反馈"
                input-align="right"
            />
            <van-field
                readonly
                name="aboutUs"
                label="关于我们"
                input-align="right"
            />
            <van-field
                readonly
                name="share"
                label="分享给他人"
                input-align="right"
            />
        </van-form>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfoForm: {},
      fullHeight: document.documentElement.clientHeight - 283.2,
    };
  },
  async created() {
    var userInfo = this.$store.getters.getUserInfo;
    if(Object.keys(userInfo).length == 0){
        this.getUserInfo();
    }else{
        this.userInfoForm = userInfo;
    }
  },
  methods: {
      toMyCollectView(){
          if(this.$route.path != "/MyCollectView"){
            this.$router.push('/MyCollectView');
          }
      },
      toMyAddressView(){
          if(this.$route.path != "/AddressView"){
            this.$router.push('/AddressView');
          }
      },
      toMyCommentView(){
          if(this.$route.path != "/MyCommentView"){
            this.$router.push('/MyCommentView');
          }
      },
      toMyFootprintView(){
          if(this.$route.path != "/MyFootprintView"){
            this.$router.push('/MyFootprintView');
          }
      },
      toMyOrderView(){
          if(this.$route.path != "/MyOrderView"){
            this.$router.push('MyOrderView');
          }
      },
      toSetView(){
          if(this.$route.path != "/SetView"){
            this.$router.push('SetView');
          }
      },
      toChangeMyInfoView(){
          if(this.$route.path != "/ChangeMyInfoView"){
            this.$router.push('ChangeMyInfoView');
          }
      },
      getUserInfo(){
        var userId = this.$store.getters.getUserId;
        this.$axios.get("/user/list/" + userId).then((res) => {
            if(res.status == 200){
                this.userInfoForm = res.data;
            }
        });
      }
  }
};
</script>

<style scoped>
.MyInfoArea {
    padding-top: 15px;
    background:#ccc;
    border-radius: 15px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.navBar {
  background-color: rgba(255, 255, 255, 0);
}
.setIcon {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.setInfo {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  width: 25px;
  margin-right: 15px;
  background-color: gainsboro;
  border-radius: 5px;
}
.editInfo {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  width: 70px;
  text-align: center;
  margin-right: 10px;
  background-color: gainsboro;
  border-radius: 5px;
}
.setting {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 14px;
  height: 32px;
}
.sex {
  width: 36px;
  font-size: 12px;
  border-radius: 5px;
  background-color: gainsboro;
  margin-left: 15px;
  margin-top: 10px;
}
.summary {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 15px;
}
.infoArea {
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
}
.userId {
  font-size: 12px;
}
.touxiangtxt {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 82px;
}
.touxiangphoto {
  width: 70px;
  height: 70px;
  border-radius: 35px;
}
.customerServiceStyle {
  color: #646566;
}
</style>