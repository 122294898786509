<template>
    <div>
        <van-nav-bar
            title="订单详情"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-address-list
            v-model="chosenAddressId"
            :list="addressList"
        />
        <div v-for="(item,index) in orderGoodsList" :key="index">
        <van-card
            :num="item.buyNum"
            :price="item.goodsPrice"
            :title="item.goodsName"
            :thumb="item.imageSrc1"
        />
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        addressList: [],
        orderGoodsList: [],
        chosenAddressId: 0
    }
  },
  created() {
      var orderId = this.$route.query.orderId;
      this.getOrderDetail(orderId);
  },
  methods: {
      onClickLeft(){
          this.$router.back();
      },
      getOrderDetail(index){
        this.$axios.get("/list/orderlist/"+index).then((res) => {
            if(res.status == 200){
                let temp = {
                    id: res.data.id,
                    name: res.data.seller,
                    tel: res.data.sellerIphone,
                    address : res.data.address
                }
                this.getOrderGoodsList(index);
                this.addressList = [];
                this.addressList.push(temp);
                this.chosenAddressId = res.data.id;
            }
        });
      },
      getOrderGoodsList(index){
        this.$axios.get("/getOrderDetailById/"+index).then((res) => {
            if(res.status == 200){
                this.orderGoodsList = [];
                for(var i=0;i<res.data.length;i++){
                    let temp = {
                        buyNum : res.data[i].buyNum,
                        goodsPrice : res.data[i].goodsPrice,
                        goodsName : res.data[i].goodsName,
                        imageSrc1 : res.data[i].imageSrc1
                    }
                    this.orderGoodsList.push(temp);
                }
            }
        });
      }
  }
};
</script>

<style scoped>
.van-address-list{
    padding: 12px 12px 0px;
}
.van-address-list__bottom{
    opacity: 0;
}
</style>