<template>
    <div>
        <van-nav-bar
            title="我的订单"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
    <div class="order_main">
    <div
        v-for="(item,index) in myOrderList"
        :key="index"
        class="order_item"
        @click="toOrderDetail(item.id)"
    >
    <van-swipe-cell>
      <div class="order_no_row">
        <div class="order_no_text">订单编号</div>
        <div class="order_no_value">{{item.id}}</div>
      </div>
      <div class="order_price_row">
        <div class="order_price_text">订单价格</div>
        <div class="order_price_value">{{"￥" + item.price}}</div>
      </div>
      <div class="order_time_row">
        <div class="order_time_text">订单日期</div>
        <div class="order_time_value">{{item.orderDay + " " + item.orderTime}}</div>
      </div>
        <template #right>
            <van-button style="height:100%" square type="danger" text="删除" @click="deleteOrder(item.id,index)"/>
        </template>
    </van-swipe-cell>
    </div>
  </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data(){
    return {
        myOrderList:[]
    };
  },
  created() {
      this.getMyOrderList();
  },
  methods: {
    onClickLeft(){
        this.$router.push("MyInfoView");
    },
    getMyOrderList(){
        var userId = this.$store.getters.getUserId;
        this.$axios.get("/list/getOrderListByUserId/" + userId + "/1").then((res) => {
            if(res.status == 200){
                this.myOrderList = res.data;
            }
        });
    },
    toOrderDetail(index){
        if(this.$route.path != "/OrderDetailView"){
            this.$router.push({path:'OrderDetailView', query:{orderId: index}});
        }
    },
    deleteOrder(id,index){
        let params = {
            ids: id
        }
        this.$axios.post("/sub/orderslistByIds",params).then((res) => {
            if(res.status == 200){
                this.myOrderList.splice(index,1);
                Toast.success('删除成功');
            }
        });
    }
  }
}
</script>


<style scoped>
.order_main{

}
.order_item{
  /* padding: 10px; */
  border-bottom: 1px solid #ccc;
  color: #666;
}
.order_no_row{
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
}
.order_no_text{

}
.order_no_value{

}
.order_price_row{
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
}
.order_price_text{

}
.order_price_value{
  color: #eb4450;
  font-size: 16px;
}
.order_time_row{
  display: flex;
  padding: 5px 10px;
  justify-content: space-between;
}
.order_time_text{

}
.order_time_value{
  
}
</style>