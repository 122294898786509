<template>
    <div>
        <div class = "titleText">购物车</div>
        <div  style="width: 100%; height:50px;"></div>
        <div v-if="cartList.length != 0">
            <div v-for="(item, index) in cartList" :key="index">
                <van-swipe-cell>
                <div style="display: flex; overflow: hidden; border-bottom: 1px solid #ccc;border-radius: 10px;">
                <div style="flex:1; background-color: #fafafa;">
                    <van-checkbox v-model="item.checked" class="checkboxStyle" @click="changeGoodCheck(index)"></van-checkbox>
                </div>
                <div style="flex:11;">
                    <van-card
                        :num="item.buyNum"
                        :price="item.goodsPrice"
                        :title="item.goodsName"
                        :thumb="item.imageSrc1"
                    >
                        <template #footer>
                            <van-button round text="+" size="mini" @click="addCartNum(index)"></van-button>
                            <van-button round text="-" size="mini" @click="subCartNum(index)">-</van-button>
                        </template>
                    </van-card>
                </div>
                </div>
                <template slot="right">
                    <van-button square text="删除" type="danger" class="delete-button" />
                </template>
                </van-swipe-cell>
            </div>
            <div style="width: 100%; height:50px;"></div>
            <van-submit-bar 
                :disabled = submitAbled
                :price="sumPrice" 
                button-text="提交订单" 
                @submit="onSubmit" />
        </div>
        <div v-if="cartList.length == 0">
            <van-empty
                class="custom-image"
                image="search"
                description="空空如也"
            />
        </div>
        <div style="width: 100%; height:50px;"></div>
    </div>
</template>

<script>
export default {
  data(){
    return {
        sumPrice: 0,
        submitAbled: true,
        cartList:[]
    };
  },
  created() {
      this.getUserCart();
  },
  methods: {
      onSubmit(){
        var Cart = this.$store.getters.getUserCart || [];
        var buyCart = [];
        for(var i=0;i<Cart.length;i++){
            if(Cart[i].checked){
                buyCart.push(Cart[i]);
            }
        }
        this.$store.commit("setBuyCart",buyCart);
        if(this.$route.path != "/OrderView"){
            this.$router.push({path:"/OrderView",query:{cartId:1}});
        }
      },
      getUserCart(){
          this.cartList = this.$store.getters.getUserCart || [];
          this.sumPrice = 0;
          for(var i=0; i<this.cartList.length; i++){
              if(this.cartList[i].checked){
                  this.sumPrice = this.sumPrice + this.cartList[i].buyNum * 100 * this.cartList[i].goodsPrice;
              }
          }
          if(this.sumPrice != 0){
              this.submitAbled = false;
          }else{
              this.submitAbled = true;
          }
      },
      changeGoodCheck(index){
          if(this.cartList[index].checked){
              this.cartList[index].checked = true;
              this.sumPrice = this.sumPrice + this.cartList[index].buyNum * 100 * this.cartList[index].goodsPrice;
          }else{
              this.cartList[index].checked = false;
              this.sumPrice = this.sumPrice - this.cartList[index].buyNum * 100 * this.cartList[index].goodsPrice;
          }
          if(this.sumPrice != 0){
              this.submitAbled = false;
          }else{
              this.submitAbled = true;
          }
          this.$store.commit("setUserCart",this.cartList);
      },
      addCartNum(index){
          this.cartList[index].buyNum++;
          if(this.cartList[index].checked){
              this.sumPrice = this.sumPrice + 100 * this.cartList[index].goodsPrice;
          }
          if(this.sumPrice != 0){
              this.submitAbled = false;
          }else{
              this.submitAbled = true;
          }
          this.$store.commit("setUserCart",this.cartList);
      },
      subCartNum(index){
          this.cartList[index].buyNum--;
          if(this.cartList[index].checked){
              this.sumPrice = this.sumPrice - 100 * this.cartList[index].goodsPrice;
          }
          if(this.cartList[index].buyNum == 0){
              this.cartList.splice(index,1);
          }
          if(this.sumPrice != 0){
              this.submitAbled = false;
          }else{
              this.submitAbled = true;
          }
          this.$store.commit("setUserCart",this.cartList);
      }
  }
}
</script>


<style scoped>
.van-submit-bar{
    bottom: 50px;
    border-bottom: 1px solid #ccc;
}
.titleText{
  font-size: 30px;
  font-weight: bold;
  padding-top: 5px;
  color: black;
  padding-bottom: 5px;
  padding-left: 10px;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 99;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.van-button--mini{
    font-size: 14px;
    width: 24px;
}
.van-card{
    font-size: 14px;
    padding-left: 0px;
}
.checkboxStyle{
    padding-left:8px;
    position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}
.delete-button {
    height: 100%;
    width: 30px;
}
</style>