<template>
  <div class="fatherStyle">
    <router-view></router-view>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" @click="toHome()">首页</van-tabbar-item>
      <van-tabbar-item icon="apps-o" @click="toKind()">分类</van-tabbar-item>
      <van-tabbar-item icon="cart-o" @click="toCart()">购物车</van-tabbar-item>
      <van-tabbar-item icon="user-o" @click="toMyInfo()">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      active: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.path == "/HomeView") {
        this.active = 0;
      } else if (this.$route.path == "/KindView") {
        this.active = 1;
      } else if (this.$route.path == "/CartView") {
        this.active = 2;
      } else if (this.$route.path == "/MyInfoView") {
        this.active = 3;
      }else{
        this.active = 0;
      }
      if(this.active == 0){
          this.toHome();
      }else if(this.active == 1){
          this.toKind();
      }else if(this.active == 2){
        this.toCart();
      }else if(this.active == 3){
        this.toMyInfo();
      }else{
          this.toHome();
      }
    },
    toHome() {
        if(this.$route.path != "/HomeView"){
            this.$router.push("/HomeView");
        }
    },
    toKind() {
        if(this.$route.path != "/KindView"){
            this.$router.push("/KindView");
        }
    },
    toCart() {
      if(this.$route.path != "/CartView"){
          this.$router.push("/CartView");
      }
    },
    toMyInfo() {
      if(this.$route.path != "/MyInfoView"){
          this.$router.push("/MyInfoView");
      }
    }
  },
};
</script>
<style scoped>
.fatherStyle{
  height: 100%;
}
</style>