<template>
    <div>
        <van-nav-bar
            title="修改收货地址"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-address-edit
            :area-list="areaList"
            :address-info="AddressInfo"
            show-postal
            show-delete
            show-set-default
            show-search-result
            :search-result="searchResult"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @delete="onDelete"
            @change-detail="onChangeDetail"
        />
    </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  data(){
    return {
      areaList,
      searchResult: [],
      timer: null,
      AddressInfo:{},
      addressId: -1,
    };
  },
  created() {
      this.addressId = this.$route.query.addressId;
      this.getUserAddress(this.addressId);
  },
  methods: {
      onClickLeft(){
          this.$router.back();
      },
      onSave(value) {
          var userId = this.$store.getters.getUserId;
          let temp = {
              id:this.addressId,
              userId:userId,
              name:value.name,
              phone:value.tel,
              province:value.province,
              city: value.city,
              area:value.county,
              completeAddress:value.addressDetail,
              postal:value.postalCode, 
              isDefault:value.isDefault ? 1 : 0,
              addressCode:value.areaCode    
          };
          this.$axios.post("/useraddress/update",temp).then((res) => {
              if(res.status == 200){
                  this.$router.back();
              }
          });
      },
      onDelete() {
          let temp = {
              id:this.addressId
          }
          this.$axios.post("/useraddress/sub",temp).then((res) => {
              if(res.status == 200){
                  this.$router.back();
              }
          });
      },
      onChangeDetail() {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
          },500);
      },
      getUserAddress(index){
        this.$axios.get("/useraddress/getOne/"+index).then((res) => {
            if(res.status == 200){
              let temp = {
                    addressDetail : res.data.completeAddress,
                    areaCode : res.data.addressCode,
                    city : res.data.city,
                    country : "",
                    county : res.data.area,
                    isDefault : res.data.isDefault == 1 ? true : false,
                    name : res.data.name,
                    postalCode : res.data.postal,
                    province : res.data.province,
                    tel : res.data.phone
              }
              this.AddressInfo = temp;
            }
        });
      }
  }
}
</script>

<style scoped>

</style>