<template>
  <div id="ChangePassword">
    <van-nav-bar
      title="编辑个人资料"
      left-text=""
      right-text="保存"
      left-arrow
      @click-left="onClickLeft()"
      @click-right="updateInfo()"
    >
    </van-nav-bar>
    <div class="ChangePassword">
      <van-field name="uploader" label="头像">
        <template #input>
          <van-uploader v-model="uploader" :max-count="1"/>
        </template>
      </van-field>
      <van-field
        v-model="nickName"
        name="昵称"
        label="昵称"
        placeholder="昵称"
        :rules="[{ required: true, message: '请填写昵称' }]"
      />
      <van-field name="性别" label="性别">
        <template #input>
          <van-radio-group v-model="gender" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="0">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="年纪"
        :value="age"
        label="年纪"
        placeholder="点击选择年纪"
        @click="showPicker = true"
      />
      <van-field
        v-model="email"
        name="邮箱"
        label="邮箱"
        placeholder="邮箱"
        :rules="[
          { required: true, message: '请填写新密码' },
          {
            pattern:
              /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: '邮箱格式错误！',
          },
        ]"
      />
      <van-field
        v-model="introduce"
        name="自我描述"
        label="自我描述"
        placeholder="自我描述"
      />
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      nickName: "",
      email: "",
      introduce: "",
      gender: "1",
      uploader: [],
      age: 1,
      columns: [],
      showPicker: false,
    };
  },
  created() {
    this.initColums();
    this.getUserInfo();
  },
  methods: {
    initColums() {
      this.columns = [];
      for (var i = 1; i < 150; i++) {
        this.columns.push(i);
      }
    },
    onConfirm(value) {
      this.age = value;
      this.showPicker = false;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getUserInfo() {
      var userId = this.$store.getters.getUserId;
      this.$axios.get("/user/list/" + userId).then((res) => {
        if (res.status == 200) {
          this.gender = res.data.gender.toString();
          this.nickName = res.data.nickName;
          this.email = res.data.email;
          this.introduce = res.data.userIntroduce;
          this.age = res.data.age;
          this.uploader = [{ content: res.data.avatarUrl }];
        }
      });
    },
    updateInfo() {
      var userId = this.$store.getters.getUserId;
      let params = {
        id: userId,
        avatar_url: this.uploader[0].content,
        gender: parseInt(this.gender),
        nickName: this.nickName,
        userIntroduce: this.introduce,
        age: this.age,
        email: this.email,
      };
        this.$axiosJSON.post("/user/update", JSON.stringify(params)).then((res) => {
          if (res.status == 200) {
            if (res.data == 1) {
              Toast.success("修改成功");
              this.$router.push("/MyInfoView");
            } else {
              Toast.fail("修改失败");
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.ChangePassword {
  width: 92%;
  margin: 0 auto;
  box-shadow: 2px 2px 10px #ddd;
  margin-top: 25px;
}
</style>