<template>
  <div>
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-swipe lazy-render :autoplay="3000">
      <van-swipe-item
        v-for="(item, index) in goodDetail.swiperImage"
        :key="index"
      >
        <img
          :src="item"
          style="width: 90%; object-fit: scale-down; padding-left: 5%"
          @click="getSwipeImg(index)"
        />
      </van-swipe-item>
    </van-swipe>
    <!-- 商品描述 -->
    <div>
      <div class="goods_price">
        {{ "￥ " + goodDetail.goodsPrice }}
      </div>
      <div class="goods_name_row">
        <div class="goods_name">{{ goodDetail.goodsName }}</div>
        <div class="goods_collect">
          <div>
            <van-icon
              :name="collectIcon"
              :color="collectColor"
              @click="changeCollectFlag"
            />
          </div>
          <div class="goods_collect_title">收藏</div>
        </div>
      </div>
    </div>
    <!-- 商品评论 -->
    <div>
      <div class="goods_comment_title">评价({{ goodComment.sumComment == undefined ? 0:  goodComment.sumComment }})</div>
      <div class="goods_comment_content" @click="toCommentView(goodComment.id)">
        <div class="goods_comment_left">
          <div class="goods_comment_left_left">
            <img
              :src="goodComment.userImage"
              style="width: 90%; object-fit: scale-down"
            />
          </div>
          <div class="goods_comment_left_right">
            <div class="goods_comment_left_right_userName">
              {{ goodComment.userName }}
            </div>
            <div class="goods_comment_left_right_comment">
              {{ goodComment.commentContent }}
            </div>
          </div>
        </div>
        <div v-if="goodComment.imageNum != 0">
          <img
            :src="goodComment.imageSrc1"
            style="width: 90%; object-fit: scale-down; padding-left: 5%"
          />
        </div>
      </div>
    </div>
    <!-- 商品详情照片 -->
    <div class="goods_info">
      <div class="goods_info_title">图文详情</div>
      <div
        class="goods_info_img"
        v-for="(item, index) in this.goodDetail.recommendImage"
        :key="index"
      >
        <img
          :src="item"
          style="width: 90%; object-fit: scale-down; padding-left: 5%"
          @click="getRecommendImg(index)"
        />
      </div>
    </div>
    <div style="height: 50px; width: 100%"></div>
    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" />
      <van-goods-action-icon icon="cart-o" text="购物车" @click="toCartView" />
      <van-goods-action-icon
        :icon="collectIcon"
        :color="collectColor"
        text="收藏"
        @click="changeCollectFlag"
      />
      <van-goods-action-button
        type="warning"
        text="加入购物车"
        @click="addCart"
      />
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="toOrderView"
      />
    </van-goods-action>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      goodDetail: {},
      goodComment: {},
      collectIcon: "star-o",
      collectColor: "black",
      goodId: -1,
    };
  },
  created() {
    var id = parseInt(this.$route.query.goodId);
    this.goodId = id;
    this.getGoodDetail(id);
    this.getGoodComment(id);
    this.getCollectFlagByUserId(id);
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    toCommentView(goodId) {
      if (this.$route.path != "/CommentView") {
        if (goodId != undefined) {
          this.$router.push({
            path: "CommentView",
            query: { GoodId: goodId },
          });
        } else {
          this.$router.push("/CommentView");
        }
      }
    },
    getSwipeImg(index) {
      if (this.goodDetail.swiperImage.length <= index) {
        return 0;
      }
      ImagePreview({
        images: this.goodDetail.swiperImage,
        showIndex: true,
        loop: false,
        startPosition: index,
        closeable: true,
      });
    },
    getRecommendImg(index) {
      if (this.goodDetail.recommendImage.length <= index) {
        return;
      }
      var imag = [];
      imag.push(this.goodDetail.recommendImage[index]);
      ImagePreview({
        images: imag,
        closeable: true,
      });
    },
    toOrderView() {
      let goodInfo = {
        id: this.goodDetail.id,
        goodsPrice: this.goodDetail.goodsPrice,
        checked: false,
        buyNum: 1,
        goodsName: this.goodDetail.goodsName,
        goodsNum: this.goodDetail.goodsNum,
        imageSrc1: this.goodDetail.swiperImage[0],
      };
      var buyCart = [];
      buyCart.push(goodInfo);
      this.$store.commit("setBuyCart", buyCart);
      if (this.$route.path != "/OrderView") {
        this.$router.push("/OrderView");
      }
    },
    getGoodDetail(id) {
      this.$axios.get("/list/goodsById/" + id).then((res) => {
        if (res.status == 200) {
          this.goodDetail = res.data;
        }
      });
    },
    getGoodComment(id) {
      this.$axios.get("/getOne/commentlist/" + id).then((res) => {
        if (res.status == 200) {
          this.goodComment = res.data;
        }
      });
    },
    getCollectFlagByUserId(id) {
      var collectFlag = 0;
      var collectList = this.$store.getters.getUserCollect;
      for (var i = 0; i < collectList.length; i++) {
        if (collectList[i].id == id) {
          collectFlag = 1;
          break;
        }
      }
      if (collectFlag == 1) {
        this.collectIcon = "star";
        this.collectColor = "red";
      } else {
        this.collectIcon = "star-o";
        this.collectColor = "black";
      }
    },
    addCollectByUserIdAndGoodId(userId, goodId) {
      var params = {
        userId: userId,
        goodId: goodId,
      };
      this.$axios
        .post("/add/collectListByUserIdAndGoodId", params)
        .then((res) => {
          if (res.status == 200) {
            var collect = this.$store.getters.getUserCollect || [];
            this.goodDetail.imageSrc1 = this.goodDetail.swiperImage[0];
            collect.push(this.goodDetail);
            this.collectIcon = "star";
            this.collectColor = "red";
            this.$store.commit("setUserCollect", collect);
            Toast.success("已收藏");
          }
        });
    },
    subCollectByUserIdAndGoodId(userId, goodId) {
      var params = {
        userId: userId,
        goodId: goodId,
      };
      this.$axios
        .post("/sub/collectListByUserIdAndGoodId", params)
        .then((res) => {
          if (res.status == 200) {
            var collect = this.$store.getters.getUserCollect || [];
            for (var i = 0; i < collect.length; i++) {
              if (collect[i].id == this.goodDetail.id) {
                collect.splice(i, 1);
                break;
              }
            }
            this.$store.commit("setUserCollect", collect);
            this.collectIcon = "star-o";
            this.collectColor = "black";
            Toast.success("取消收藏");
          }
        });
    },
    changeCollectFlag() {
      var userId = this.$store.getters.getUserId;
      if (this.collectIcon == "star-o") {
        //未收藏
        this.addCollectByUserIdAndGoodId(userId, this.goodId);
      } else {
        //已收藏
        this.subCollectByUserIdAndGoodId(userId, this.goodId);
      }
    },
    addCart() {
      var userId = this.$store.getters.getUserId;
      var params = {
        userId: userId,
        goodId: this.goodId,
      };
      this.$axios.post("/add/cartListByUserIdAndGoodId", params).then((res) => {
        if (res.status == 200) {
          var cart = this.$store.getters.getUserCart || [];
          var findFlag = 0;
          for (var i = 0; i < cart.length; i++) {
            if (cart[i].id == this.goodDetail.id) {
              cart[i].buyNum++;
              findFlag = 1;
              break;
            }
          }
          if (findFlag == 0) {
            let goodInfo = {
              id: this.goodDetail.id,
              goodsPrice: this.goodDetail.goodsPrice,
              checked: false,
              buyNum: 1,
              goodsName: this.goodDetail.goodsName,
              goodsNum: this.goodDetail.goodsNum,
              imageSrc1: this.goodDetail.swiperImage[0],
            };
            cart.push(goodInfo);
            this.$store.commit("setUserCart", cart);
          }
          Toast.success("已加入购物车");
        }
      });
    },
    toCartView() {
      if (this.$route.path != "/CartView") {
        this.$router.push("/CartView");
      }
    },
  },
};
</script>

<style scoped>
.goods_price {
  color: #eb4450;
  font-weight: 600;
  font-size: 18px;
  padding: 3px 10px;
}
.goods_name_row {
  display: flex;
  border-bottom: 5px solid #dedede;
  border-top: 5px solid #dedede;
  padding: 5px 0;
}
.goods_name {
  flex: 5;
  color: #000;
  font-size: 15px;
  padding: 0 10px;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.goods_collect {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-left: 1px solid #000;
}
.goods_collect_title {
  color: #000;
  font-size: 15px;
}
.goods_comment_title {
  color: #eb4450;
  font-size: 15px;
  font-weight: 600;
  padding: 3px 10px;
}
.goods_comment_content {
  margin-left: 10px;
  border-bottom: 5px solid #dedede;
  padding-bottom: 10px;
}
.goods_comment_left {
  display: flex;
  flex: 4;
}
.goods_comment_left_left {
  flex: 1;
}
.goods_comment_left_right {
  flex: 4;
}
.goods_comment_left_right_userName {
  font-size: 13px;
  color: grey;
}
.goods_comment_left_right_comment {
  margin-top: 7px;
  padding-bottom: 10px;
  word-break: break-all;
  word-wrap: break-word;
}
.goods_info {
  display: flex;
  flex-direction: column;
}
.goods_info_title {
  color: #eb4450;
  font-size: 20px;
  font-weight: 600;
  padding: 3px 10px;
}
.goods_info_img {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>