<template>
    <div>
        <van-nav-bar
            title="商品列表"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-search
            @click="toSearchView"
            :readonly = true
            shape="round"
            background="writhe"
            placeholder="搜索"
        />
        <van-tabs v-model="orderActive" >
            <van-tab title="综合">
                <div>
                    <div 
                    v-for="(item,index) in synthesisList" 
                    :key="index" 
                    :class='[index ? "good_border_style_2" : "good_border_style_1"]' 
                    @click="toGoodDetail(item.id)">
                        <van-row type="flex" gutter="20">
                            <van-col span="8">
                                <div style="border-radius: 20px;width: 90%;padding-left: 5%;">
                                    <img :src="item.imageSrc1" style="width: 90%;object-fit:scale-down; padding-left:5%;"/>
                                </div>
                            </van-col>
                            <van-col span="16">
                                <div style="position: relative;height:100%">
                                    <div class="goods_name">
                                        {{ item.goodsName }}
                                    </div>
                                    <div class="goods_price">
                                        ￥{{ item.goodsPrice }}
                                    </div>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </van-tab>
            <van-tab title="销量">
                <div>
                    <div 
                    v-for="(item,index) in salesList" 
                    :key="index" 
                    :class='[index ? "good_border_style_2" : "good_border_style_1"]'
                    @click="toGoodDetail(item.id)">
                        <van-row type="flex" gutter="20">
                            <van-col span="8">
                                <div style="border-radius: 20px;width: 90%;padding-left: 5%;">
                                    <img :src="item.imageSrc1" style="width: 90%;object-fit:scale-down; padding-left:5%;"/>
                                </div>
                            </van-col>
                            <van-col span="16">
                                <div style="position: relative;height:100%">
                                    <div class="goods_name">
                                        {{ item.goodsName }}
                                    </div>
                                    <div class="goods_price">
                                        ￥{{ item.goodsPrice }}
                                    </div>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </van-tab>
            <van-tab title="价格">
                <div>
                    <div 
                    v-for="(item,index) in priceList" 
                    :key="index" 
                    :class='[index ? "good_border_style_2" : "good_border_style_1"]'
                    @click="toGoodDetail(item.id)">
                        <van-row type="flex" gutter="20">
                            <van-col span="8">
                                <div style="border-radius: 20px;width: 90%;padding-left: 5%;">
                                    <img :src="item.imageSrc1" style="width: 90%;object-fit:scale-down; padding-left:5%;"/>
                                </div>
                            </van-col>
                            <van-col span="16">
                                <div style="position: relative;height:100%">
                                    <div class="goods_name">
                                        {{ item.goodsName }}
                                    </div>
                                    <div class="goods_price">
                                        ￥{{ item.goodsPrice }}
                                    </div>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
  data(){
    return {
        orderActive: 0,
        synthesisList: [],
        salesList: [],
        priceList: [],
    };
  },
  created() {
      var id;
      if(this.$route.query.kindChildrenId != undefined){
          id = parseInt(this.$route.query.kindChildrenId);
          this.getGoodListByChrildenId(id,0);
          this.getGoodListByChrildenId(id,1);
          this.getGoodListByChrildenId(id,2);
      }else if(this.$route.query.kindId != undefined){
          id = parseInt(this.$route.query.kindId);
          this.getGoodListByCateId(id,0);
          this.getGoodListByCateId(id,1);
          this.getGoodListByCateId(id,2);
      }
  },
  methods: {
    onClickLeft(){
        this.$router.back();
    },
    toSearchView(){
        if(this.$route.path != "/SearchView"){
            this.$router.push("/SearchView");
        }
    },
    getGoodListByChrildenId(id,type){
        this.$axios.get("/list/goodslistBycateId/" + id + "/" + type).then((res) => {
            if(res.status == 200){
                if(type == 0){
                    this.synthesisList = res.data;
                }else if(type == 1){
                    this.salesList = res.data;
                }else{
                    this.priceList = res.data;
                }
            }
        });
    },
    getGoodListByCateId(id,type){
        this.$axios.get("/list/goodslistBycateId_big/" + id + "/" + type).then((res) => {
            if(res.status == 200){
                if(type == 0){
                    this.synthesisList = res.data;
                }else if(type == 1){
                    this.salesList = res.data;
                }else{
                    this.priceList = res.data;
                }
            }
        });
    },
    toGoodDetail(value){
        if(this.$route.path != "/GoodDetailView"){
            this.$router.push({path:'GoodDetailView', query:{goodId: value}});
        }
    }
  }
}
</script>

<style scoped>
.good_border_style_1{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.good_border_style_2{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
}
.goods_name{
  font-size: 14px;
  font-weight: bold;
}
.goods_price{
    color: #eb4450;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    bottom: 0%;
}
</style>