<template>
  <div id="Chat">
    <van-nav-bar
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    >
      <template slot="title">
        <span style="font-size: 14px; font-weight: bold">我的评论</span>
      </template>
    </van-nav-bar>
    <div class="mainInfo">
      <ul>
        <van-swipe-cell
          v-for="(item, index) in this.commentList"
          :key="item.id"
        >
          <div style="height: 15px"></div>
          <li style="margin-bottom: 20px">
            <div class="oneInfo">
              <div class="oneInfoHead">
                <div class="infoArea">
                  <img
                    class="touxiangphoto float-left"
                    :src="item.userImage"
                    alt=""
                  />
                  <div class="touxiangtxt">
                    <div>
                      <span class="username">{{ item.userName }}</span>
                    </div>
                    <div>
                      <span class="issTime">{{ item.createTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div @click="toCommentDetail(item)">
                <div class="mainTxt">
                  <span>{{ item.commentContent }}</span>
                </div>
              </div>
              <div class="mainPhoto">
                <img
                  v-if="item.imageSrc1"
                  :src="item.imageSrc1"
                  @click="showFunction(item.imageSrc1)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc2"
                  :src="item.imageSrc2"
                  @click="showFunction(item.imageSrc2)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc3"
                  :src="item.imageSrc3"
                  @click="showFunction(item.imageSrc3)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc4"
                  :src="item.imageSrc4"
                  @click="showFunction(item.imageSrc4)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc5"
                  :src="item.imageSrc5"
                  @click="showFunction(item.imageSrc5)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc6"
                  :src="item.imageSrc6"
                  @click="showFunction(item.imageSrc6)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc7"
                  :src="item.imageSrc7"
                  @click="showFunction(item.imageSrc7)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc8"
                  :src="item.imageSrc8"
                  @click="showFunction(item.imageSrc8)"
                  class="onePhoto"
                  alt=""
                />
                <img
                  v-if="item.imageSrc9"
                  :src="item.imageSrc9"
                  @click="showFunction(item.imageSrc9)"
                  class="onePhoto"
                  alt=""
                />
              </div>
            </div>
          </li>
          <template #right>
            <van-button
              style="height: 100%"
              @click="deleteMyComment(index, item.id)"
              square
              type="danger"
              text="删除"
            />
          </template>
        </van-swipe-cell>
      </ul>
    </div>
    <van-image-preview v-model="show" :images="images" :showIndex="false">
    </van-image-preview>
    <div style="height: 50px; width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commentList: [],
      show: false,
      images: [],
    };
  },
  created() {
    this.getMyCommentList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    deleteMyComment(index, commentId) {
      let params = {
        id: commentId,
      };
      this.$axios.post("/sub/commentList", params).then((res) => {
        if (res.status == 200) {
          this.commentList.splice(index, 1);
        }
      });
    },
    showFunction(item) {
      this.images = [];
      this.show = true;
      this.images.push(item);
    },
    toCommentDetail(item) {
      if (this.$route.path != "/CommentDetailView") {
        this.$router.push({
          path: "CommentDetailView",
          query: { CommentId: item.id },
        });
      }
    },
    getMyCommentList() {
      var userId = this.$store.getters.getUserId;
      this.$axios.get("/list/commentlistByUserId/" + userId).then((res) => {
        if (res.status == 200) {
          this.commentList = res.data;
          console.log(res.data);
        }
      });
    },
  },
};
</script>


<style scoped>
.oneDiv {
  width: 50%;
  text-align: center;
  font-size: 13px;
  display: inline-block;
}
.iconDiv {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: 42px;
  border-radius: 21px;
  border: 2px black solid;
}
.popuphead {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
}
.addComment {
  position: fixed;
  right: 3%;
  bottom: 15%;
}
.onePhoto {
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.mainPhoto {
  margin-top: 4px;
  margin-left: 10px;
}
.mainTxt {
  font-size: 12px;
  margin: 0 auto;
  width: 96%;
  padding-bottom: 6px;
  word-break: break-all;
  word-wrap: break-word;
}
.txtTitle {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  padding-bottom: 6px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.username {
  font-size: 12px;
  font-weight: bold;
}
.issTime {
  font-size: 12px;
}
.touxiangtxt {
  margin-left: 56px;
}
.touxiangphoto {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 8px;
}
.oneInfo {
  width: 100%;
  box-shadow: 0 15px 10px -15px #ddd;
}
.mainInfo {
  width: 100%;
}
.txt-bold {
  font-size: 14px;
  font-weight: bold;
  border-bottom: 3px solid black;
}
.txt {
  font-size: 14px;
}
.photo {
  border-radius: 3px;
  width: 40px;
  height: 40px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.small-txt {
  font-size: 12px;
  text-align: center;
}
</style>