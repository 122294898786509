<template>
  <div>
    <van-nav-bar
      title="我的足迹"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div>
      <div v-for="(item, index) in footprintList" :key="index">
        <div
          :class="[index ? 'good_border_style_2' : 'good_border_style_1']"
          @click="toGoodDetail(item.id)"
        >
          <van-row type="flex" gutter="20">
            <van-col span="8">
              <div style="border-radius: 20px; width: 90%; padding-left: 5%">
                <img
                  :src="item.imageSrc1"
                  style="width: 90%; object-fit: scale-down; padding-left: 5%"
                />
              </div>
            </van-col>
            <van-col span="16">
              <div style="position: relative; height: 100%">
                <div class="goods_name">
                  {{ item.goodsName }}
                </div>
                <div class="goods_price">￥{{ item.goodsPrice }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footprintList: [],
    };
  },
  created() {
    this.getMyFootprintList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    getMyFootprintList() {
        var userId = this.$store.getters.getUserId;
        this.$axios.get("/list/footprint/" + userId).then((res) => {
            if(res.status == 200){
                this.footprintList = res.data || [];
            }
        });
    },
    toGoodDetail(value) {
      if (this.$route.path != "/GoodDetailView") {
        this.$router.push({ path: "GoodDetailView", query: { goodId: value } });
      }
    },
    deleteMyCollect(index,goodId){
        var userId = this.$store.getters.getUserId;
        var params = {
            userId: userId,
            goodId: goodId
        };
        this.$axios.post("/sub/collectListByUserIdAndGoodId",params).then((res) => {
            if(res.status == 200){
              var collect = this.$store.getters.getUserCollect || [];
              for(var i=0; i<collect.length; i++){
                if(collect[i].id == this.goodId){
                  collect.splice(i,1);
                  break;
                }
              }
              this.$store.commit("setUserCollect",collect);
              this.collectList.splice(index,1);
            }
        });
    }
  },
};
</script>

<style scoped>
.good_border_style_1 {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.good_border_style_2 {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.goods_name {
  font-size: 14px;
  font-weight: bold;
}
.goods_price {
  color: #eb4450;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  bottom: 0%;
}
</style>