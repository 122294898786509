<template>
  <div>
    <van-nav-bar
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    >
      <template slot="title">
        <span style="font-size: 14px; font-weight: bold">发布评论</span>
      </template>
    </van-nav-bar>
    <div class="photoUploader">
      <van-uploader v-model="fileList" multiple :max-count="9" />
    </div>
    <div class="inputArea">
      <van-form @submit="addCommentInfo">
        <van-cell-group inset>
          <van-rate
            v-model="rateValue"
            allow-half
            void-icon="star"
            void-color="#eee"
          />
          <van-field
            v-model="tableData.content"
            rows="5"
            type="textarea"
            maxlength="5000"
            autosize
            show-word-limit
            placeholder="请输入正文"
            :rules="[{ required: true, message: '请填写正文' }]"
          >
          </van-field>
          <van-goods-action>
            <van-goods-action-button
              type="danger"
              text="发布"
              native-type="submit"
            />
          </van-goods-action>
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      tableData: {},
      rateValue: 0,
      goodId: -1,
    };
  },
  created() {
    this.goodId = parseInt(this.$route.query.GoodId);
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    addCommentInfo() {
      // 图片的base64码this.fileList[i].content
      //成功后
      var userId = this.$store.getters.getUserId;
      var imgs = "";
      for (var i = 0; i < this.fileList.length; i++) {
        if (i == 0) {
          imgs = imgs + this.fileList[i].content;
        } else {
          imgs = imgs + "%%%%" + this.fileList[i].content;
        }
      }
      let params = {
        userId: userId,
        goodId: this.goodId,
        commentImages: imgs,
        commentContent: this.tableData.content,
      };
      this.$axiosJSON.post("/add/commentList", JSON.stringify(params)).then((res) => {
        if (res.status == 200) {
          this.$router.back();
        }
      });
    },
  },
};
</script>

<style scoped>
.inputArea {
  margin-top: 5px;
}
.photoUploader {
  margin-top: 10px;
  margin-left: 10px;
}
</style>