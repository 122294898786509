<template>
  <div id="Login">
    <div class="title">
      <span>账号登陆</span>
    </div>
    <div class="form">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="loginForm.username"
            name="username"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="loginForm.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        </van-cell-group>
        <div style="margin: 16px; margin-top: 50px">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="method">
      <span @click="findBackPassword">找回密码</span>
      <span style="margin-left: 50px; margin-right: 50px; color: gainsboro"
        >|</span
      >
      <span @click="toRegisterView">账户注册</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      loginForm: { username: "", password: "" },
    };
  },
  created() {},
  methods: {
    findBackPassword() {
      Dialog.alert({
        title: "找回密码",
        message: "请联系客服火田夕夕",
      }).then(() => {
        // on close
      });
    },
    onSubmit() {
      let params = {
        phone: this.loginForm.username,
        password: this.loginForm.password,
      };
      this.$axios.post("/user/login", params).then((res) => {
        if (res.status == 200) {
          var temp = res.data.toString();
          var index = temp.indexOf("@");
          if (index != -1) {
            var indexend = temp.lastIndexOf("@");
            var tempToken = temp.substring(indexend + 1);
            var userId = parseInt(temp.substring(index + 1, indexend));
            this.$store.commit("setUserId", userId);
            this.$store.commit("setToken", tempToken);
            this.getMyCollect(tempToken);
            this.getMyCart(tempToken);
            Toast.success("登录成功");
            this.$router.push("/HomeView");
          } else {
            if (temp == "1") {
              Toast.fail("账号密码错误");
            } else {
              Toast.fail("没有该账号信息");
            }
            this.loginForm = { username: "", password: "" };
          }
        }
      });
    },
    toRegisterView() {
      if (this.$route.path != "/RegisterView") {
        this.$router.push("/RegisterView");
      }
    },
    getMyCollect(token) {
      this.$axios.get("/list/collectlist/" + token).then((res) => {
        if (res.status == 200) {
          var collect = res.data || [];
          this.$store.commit("setUserCollect", collect);
        }
      });
    },
    getMyCart(token) {
      this.$axios.get("/list/catrlist/" + token).then((res) => {
        if (res.status == 200) {
          var cart = res.data || [];
          this.$store.commit("getUserCart", cart);
        }
      });
    },
  },
};
</script>

<style scoped>
.method {
  width: 100%;
  text-align: center;
  font-size: 16px;
  position: absolute;
  top: 90%;
}
.title {
  position: absolute;
  margin-left: 30px;
  font-size: 25px;
  font-weight: bold;
  top: 15%;
}
.form {
  position: absolute;
  top: 25%;
  width: 100%;
}
</style>