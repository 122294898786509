import axios from "axios";
// import router from "./router";
axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = 'https://www.yechen.club:444/'
const request = axios.create({
	timeout: 20000,
	headers: {
		// 'Content-Type': "application/json; charset=utf-8"
		'Content-Type':'application/x-www-form-urlencoded;charset=utf-8;'
	}
})
export default request