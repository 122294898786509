<template>
    <div>
        <van-nav-bar
            title="搜索"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <form action="/">
            <van-search
                v-model="inputValue"
                shape="round"
                show-action
                placeholder="请输入搜索商品关键词"
                @input="onSearch"
                @cancel="onCancel"
                background="white"
            />
        </form>
        <div style="width: 100%; height:54px;"></div>
        <div v-if="this.allSearchList.length != 0">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <van-cell 
                v-for="(item,index) in searchList" 
                :key="index" 
                :title="item.goodsName" 
                @click="toGoodDetailView(item.id)"/>
            </van-list>
        </div>
    </div>
</template>

<script>
export default {
  data(){
    return {
        inputValue: '',
        allSearchList: [],
        searchList: [],
        loading: false,
        finished: false,
        timer: null
    };
  },
  created() {
  },
  methods: {
      onClickLeft(){
          this.$router.back();
      },
      onSearch(){
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
              if(this.inputValue == ""){
                  return ;
              }
              this.$axios.get("/list/goodsByName/" + this.inputValue).then((res) => {
                if(res.status == 200){
                    this.allSearchList = res.data || [];
                    this.onLoad();
                }
              });
          },500);
      },
      onCancel(){
          this.inputValue = '';
          this.searchList = [];
          this.allSearchList = [];
      },
      onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
            if(this.searchList.length == this.allSearchList.length){
                break;
            }
            this.searchList.push(this.allSearchList[this.searchList.length]);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.searchList.length == this.allSearchList.length) {
          this.finished = true;
        }
      }, 1000);
    },
    toGoodDetailView(id){
        if(this.$route.path != "/GoodDetailView"){
            this.$router.push({path:'GoodDetailView', query:{goodId: id}});
        }
    }
  }
}
</script>

<style scoped>
.van-search{
    height: 54px;
    position: fixed;
    width: 100%;
}
</style>