import store from '../store/';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/LoginMain.vue'
import Home from '../views/MainHome.vue'
import MainTable from '../views/MainTable.vue';
import HomeView from '../views/HomeView.vue'
import KindView from '../views/KindView.vue'
import CartView from '../views/CartView.vue'
import SearchView from '../views/SearchView.vue'
import RegisterView from '../views/RegisterView.vue'
import MyInfoView from '../views/MyInfoView.vue'
import GoodDetailView from '../views/GoodDetailView.vue'
import GoodListView from '../views/GoodListView.vue'
import AddressView from '../views/AddressView.vue'
import EditAddressView from '../views/EditAddressView.vue'
import AddAddressView from '../views/AddAddressView.vue'
import OrderView from '../views/OrderView.vue'
import CommentView from '../views/CommentView.vue'
import AddCommentView from '../views/AddCommentView.vue'
import CommentDetailView from '../views/CommentDetailView.vue'
import MyOrderView from '../views/MyOrderView.vue'
import OrderDetailView from '../views/OrderDetailView.vue'
import MyCollectView from '../views/MyCollectView.vue'
import MyFootprintView from '../views/MyFootprintView.vue'
import MyCommentView from '../views/MyCommentView.vue'
import SetView from '../views/SetView.vue'
import ChangeMyInfoView from '../views/ChangeMyInfoView.vue'
import ChangePasswordView from '../views/ChangePasswordView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
		name: 'Home',
		component: Home
  },
  {
    path: '/ChangePasswordView',
		name: 'ChangePasswordView',
		component: ChangePasswordView
  },
  {
    path: '/SetView',
		name: 'SetView',
		component: SetView
  },
  {
    path: '/ChangeMyInfoView',
		name: 'ChangeMyInfoView',
		component: ChangeMyInfoView
  },
  {
    path: '/MyFootprintView',
		name: 'MyFootprintView',
		component: MyFootprintView
  },
  {
    path: '/MyCommentView',
		name: 'MyCommentView',
		component: MyCommentView
  },
  {
    path: '/MyCollectView',
		name: 'MyCollectView',
		component: MyCollectView
  },
  {
		path: '/Login',
		name: 'Login',
		component: Login
  },
  {
		path: '/MainTable',
		name: 'MainTable',
		component: MainTable,
    children:[
      {
        path: '/HomeView',
        name: 'HomeView',
        component: HomeView
      },
      {
        path: '/KindView',
        name: 'KindView',
        component: KindView
      },
      {
        path: '/CartView',
        name: 'CartView',
        component: CartView
      },
      {
        path: '/MyInfoView',
        name: 'MyInfoView',
        component: MyInfoView
      }
    ]
  },
  {
		path: '/SearchView',
		name: 'SearchView',
		component: SearchView
  },
  {
		path: '/RegisterView',
		name: 'RegisterView',
		component: RegisterView
  },
  {
		path: '/GoodDetailView',
		name: 'GoodDetailView',
		component: GoodDetailView
  },
  {
		path: '/GoodListView',
		name: 'GoodListView',
		component: GoodListView
  },
  {
		path: '/AddressView',
		name: 'AddressView',
		component: AddressView
  },
  {
		path: '/AddAddressView',
		name: 'AddAddressView',
		component: AddAddressView
  },
  {
		path: '/EditAddressView',
		name: 'EditAddressView',
		component: EditAddressView
  },
  {
		path: '/OrderView',
		name: 'OrderView',
		component: OrderView
  },
  {
		path: '/CommentView',
		name: 'CommentView',
		component: CommentView
  },
  {
		path: '/AddCommentView',
		name: 'AddCommentView',
		component: AddCommentView
  },
  {
		path: '/CommentDetailView',
		name: 'CommentDetailView',
		component: CommentDetailView
  },
  {
    path: '/MyOrderView',
		name: 'MyOrderView',
		component: MyOrderView
  },
  {
    path: '/OrderDetailView',
		name: 'OrderDetailView',
		component: OrderDetailView
  }
]

const router = new VueRouter({
	base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = store.state.token;
  if(to.path == '/Login' || to.path == '/RegisterView'){
    if(token){
      next({ path: '/HomeView' });
    }else{
      next();
    }
  }else if(!token) {
    if(to.path != '/'){
      next({ path: '/Login' });
    }else{
      next();
    }
  }else{
      next();
  }
})

export default router