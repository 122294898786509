<template>
  <div id="Register">
    <van-nav-bar left-arrow @click-left="onClickLeft" :border="false" />
    <div class="title">
      <span>账号注册</span>
    </div>
    <div class="form">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="registerForm.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
            v-model="rePassword"
            type="password"
            name="重复密码"
            label="重复密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' },
            {validator:checkPassword,message: '两次密码输入不一致'}]"
          />
          <van-field
            v-model="registerForm.userMail"
            name="注册邮箱"
            label="注册邮箱"
            placeholder="注册邮箱"
            :rules="[{ required: true, message: '请填写注册邮箱' },
            { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '邮箱格式错误！'}
            ]"
          />
          <van-field
            v-model="registerForm.phone"
            name="注册手机"
            label="注册手机"
            placeholder="注册手机"
            :rules="[
              { required: true, message: '请填写注册手机' },
              { validator: verifyPhone, message: '请输入正确的手机号码' },
            ]"
          />
        </van-cell-group>
        <div style="margin: 16px; margin-top: 50px">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      registerForm: { password: "", userMail: "", phone: "" },
      rePassword: "",
      account: "",
      show: false,
      mistakeShow: false,
      mistake: ""
    };
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    verifyPhone(val) {
      return /^1\d{10}$/.test(val);
    },
    checkPassword(){
      if(this.rePassword == ""){
        return false;
      }else if(this.rePassword != this.registerForm.password){
        return false;
      }else{
        return true;
      }
    },
    //进行异步请求
    onSubmit() {
        let params = {
            phone: this.registerForm.phone,
            password: this.registerForm.password,
            email: this.registerForm.userMail
        }
        this.$axios.post("/user/register",params).then((res) => {
            if(res.status == 200){
              if(res.data == 1){
                  Toast.success('注册成功');
                  this.$router.push("/Login");
              }else{
                  Toast.fail('该手机号已被注册');
                  this.registerForm = { password: "", userMail: "", phone: "" };
                  this.rePassword = "";
              }
            }
        });
    },
  },
};
</script>

<style scoped>
.method {
  width: 100%;
  text-align: center;
  font-size: 16px;
  position: absolute;
  top: 90%;
}
.title {
  position: absolute;
  margin-left: 30px;
  font-size: 25px;
  font-weight: bold;
  top: 7%;
}
.form {
  position: absolute;
  top: 18%;
  width: 100%;
}
</style>