import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderAddress:{},
    kindActiveKey: 0,
    userId: 5,
    userCollect: [],
    userCart: [],
    buyCart: [],
    token:"",
    userInfo:{}
  },
  getters: {
    getOrderAddress(state){
      return state.orderAddress;
    },
    getKindActiveKey(state){
      return state.kindActiveKey;
    },
    getUserId(state){
      return state.userId;
    },
    getUserCollect(state){
      return state.userCollect;
    },
    getUserCart(state){
      return state.userCart;
    },
    getBuyCart(state){
      return state.buyCart;
    },
    getToken(state){
      return state.token;
    },
    getUserInfo(state){
      return state.userInfo;
    }
  },
  mutations: {
    setOrderAddress(state,orderAddress){
      state.orderAddress = orderAddress;
    },
    setKindActiveKey(state,kindActiveKey){
      state.kindActiveKey = kindActiveKey;
    },
    setUserId(state,userId){
      state.userId = userId;
    },
    setUserCollect(state,userCollect){
      state.userCollect = userCollect;
    },
    setUserCart(state,userCart){
      state.userCart = userCart;
    },
    setBuyCart(state,buyCart){
      state.buyCart = buyCart;
    },
    setToken(state,token){
      state.token = token;
    },
    setUserInfo(state,userInfo){
      state.userInfo = userInfo;
    }
  },
  actions: {
  },
  modules: {
  }
})
