<template>
  <div id="CommentDetail">
    <van-nav-bar
      title="评论详情"
      left-arrow
      @click-left="onClickLeft()"
      :border="false"
    />
    <div class="oneInfoHead">
      <div class="infoArea">
        <img
          class="touxiangphoto float-left"
          :src="commentInfo.userImage"
          alt=""
        />
        <div class="touxiangtxt">
          <div>
            <span class="username">{{ commentInfo.userName }}</span>
          </div>
          <div>
            <span class="issTime">{{ commentInfo.createTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-swipe :autoplay="3000" class="swipeStyle">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img :src="image.img" style="width: 80%"/>
      </van-swipe-item>
    </van-swipe>
    <div class="mainTxt">
      <span>{{ commentInfo.commentContent }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commentInfo: {},
      images: [],
    };
  },
  created() {
    var commentId = parseInt(this.$route.query.CommentId);
    this.getcommentDetail(commentId);
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    getcommentDetail(commentId) {
      this.$axios.get("/getOne/commentlistbyId/" + commentId).then((res) => {
        if (res.status == 200) {
          this.commentInfo = res.data || {};
          console.log(res.data);
          if (res.data.imageNum >= 1) {
            this.images.push({img:res.data.imageSrc1});
          }
          if (res.data.imageNum >= 2) {
            this.images.push({img:res.data.imageSrc2});
          }
          if (res.data.imageNum >= 3) {
            this.images.push({img:res.data.imageSrc3});
          }
          if (res.data.imageNum >= 4) {
            this.images.push({img:res.data.imageSrc4});
          }
          if (res.data.imageNum >= 5) {
            this.images.push({img:res.data.imageSrc5});
          }
          if (res.data.imageNum >= 6) {
            this.images.push({img:res.data.imageSrc6});
          }
          if (res.data.imageNum >= 7) {
            this.images.push({img:res.data.imageSrc7});
          }
          if (res.data.imageNum >= 8) {
            this.images.push({img:res.data.imageSrc8});
          }
          if (res.data.imageNum >= 9) {
            this.images.push({img:res.data.imageSrc9});
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.oneInfoHead {
  margin-top: 10px;
}
.username {
  font-size: 12px;
  font-weight: bold;
}
.issTime {
  font-size: 12px;
}
.touxiangtxt {
  margin-left: 56px;
}
.touxiangphoto {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 8px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.swipeStyle {
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  text-align: center;
}
.mainTxt {
  font-size: 14px;
  margin: 0 auto;
  width: 96%;
  padding-bottom: 6px;
  word-break: break-all;
  word-wrap: break-word;
}
</style>